.experience-container {
  display: flex;
  width: 90%;
  margin: auto;
  margin-top: 15px;
  border-radius: 20px;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.experience-selector-bloc {
  border: solid 1px #263b5a;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 15px;
  margin-bottom: 0;
  margin-top: 20px;
  cursor: pointer;

  h3 {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

.selected-experience {
  border: solid 5px #263b5a;
}

@media (max-width: 1200px) {
  .experience-container {
    display: none;
  }
}
