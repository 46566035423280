@font-face {
  font-family: "Tommy";
  src: url("./assets/tommy.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #050826;
  margin: 0;

  font-family: "Tommy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: #b6acf1;
}

::-webkit-scrollbar-thumb {
  background-color: #404ac0;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1d257e;
}

::-webkit-scrollbar-button:single-button {
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  content: "\25B2";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 6l-8 8h16l-8-8z'/%3E%3C/svg%3E");
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  content: "\25BC";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 18l8-8H4l8 8z'/%3E%3C/svg%3E");
}
