$transparent: rgba(0, 0, 0, 0);

#contact {
  margin-top: 40px;
  padding-top: 20px;
}

.contact-bloc {
  display: flex;
  justify-content: center;
  padding: 20px 20px 30px 20px;
}

.form-line {
  width: 100%;
  display: flex;
}

.input {
  background-color: $transparent;
  border: none;
  color: white;
  width: 100%;
  height: 100%;
}

input:focus {
  outline: none;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + label::after {
  content: "✔";
  color: white;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
}

.fieldset-large {
  width: 89%;
}

.message {
  height: 200px;
}

.message-envoye {
  color: greenyellow;
}

.bloc-checkbox {
  display: flex;
  justify-content: center;
}

.disclaimer {
  color: white;
  margin-top: 0;
  text-align: left;
  margin-left: 10px;
  width: 750px;
}

.form-error {
  color: #f74251;
}

.submit {
  background-color: $transparent;
  color: white;
  border: solid 1px white;
  border-radius: 12px;
  padding: 12px 18px 12px 18px;
}

.coordos {
  text-align: left;
  color: white;
}

label {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  background-color: $transparent;
}

form {
  width: 45%;
}

fieldset {
  width: 40%;
  height: 40px;
  border-radius: 10px;
  border: solid 1px white;
  color: white;
  margin-right: 5%;
  margin-bottom: 20px;
}

fieldset:focus-within {
  border: solid 3px white;
}

textarea:focus {
  outline: none;
}

textarea {
  resize: none;
}

@media (max-width: 800px) {
  .contact-bloc {
    flex-direction: column;
  }

  .coordos {
    margin: auto;
  }

  form {
    width: 90%;
    margin: auto;
  }
}
